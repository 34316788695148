import * as z from 'zod'
import { EmailAddressSchema, LocalDateSchema } from '@digital-magic/zod-common-schemas'
import { UserLanguageEnumSchema } from '@model/language'
import {
  CaptchaTokenSchema,
  EnginePowerSchema,
  EngineVolumeSchema,
  FileIdSchema,
  FormIdSchema,
  MileageSchema,
  PersonalIdCodeSchema,
  PhoneNumberSchema,
  PriceSchema,
  UserNameSchema,
  VehicleIdSchema,
  VehicleLocationSchema,
  VehicleMakeSchema,
  VehicleModelSchema,
  VehicleRegistrationNumberSchema,
  VehicleYearSchema
} from '@api/endpoints'
import {
  FuelTypeTranslationIdSchema,
  FuelTypeTranslationValueSchema,
  TransmissionTypeTranslationIdSchema,
  TransmissionTypeTranslationValueSchema
} from '@api/endpoints/translations'

export const RequestOriginSchema = z.enum(['CARBUY_ORIGIN', 'CARPROF_ORIGIN'])
export type RequestOrigin = z.infer<typeof RequestOriginSchema>

export const TopicEnumSchema = z.enum([
  'leasing',
  'installment',
  'exchange',
  'buyback',
  'commission',
  'guarantee',
  'other'
])
export type TopicEnum = z.infer<typeof TopicEnumSchema>

export const FormTypeSchema = z.enum(['LEASE', 'CAR_LOAN', 'COMMISSION', 'BUYOUT', 'TRADE_IN'])
export type FormType = z.infer<typeof FormTypeSchema>

export const CarLoanPeriodEnumSchema = z.enum([
  'MONTHS_12',
  'MONTHS_24',
  'MONTHS_36',
  'MONTHS_48',
  'MONTHS_60',
  'MONTHS_72',
  'MONTHS_84',
  'MONTHS_96'
])
export type CarLoanPeriodEnum = z.infer<typeof CarLoanPeriodEnumSchema>

export const CivilStatusTypeEnumSchema = z.enum(['SINGLE', 'MARRIED', 'DIVORCED', 'COMMON_LAW_MARRIAGE', 'WIDOW'])
export type CivilStatusTypeEnum = z.infer<typeof CivilStatusTypeEnumSchema>

export const EducationTypeEnumSchema = z.enum(['HIGHER', 'PRIMARY', 'SECONDARY', 'VOCATIONAL', 'COLLEGE'])
export type EducationTypeEnum = z.infer<typeof EducationTypeEnumSchema>

export const OccupationTypeEnumSchema = z.enum([
  'EXECUTIVE',
  'WORKER',
  'SPECIALIST_OR_OFFICE_WORKER',
  'SUPERIOR_SPECIALIST',
  'MIDDLE_MANAGER',
  'OWNER',
  'STUDENT',
  'PENSIONER',
  'AT_HOME',
  'UNEMPLOYED',
  'PRIVATE_ENTREPRENEUR'
])
export type OccupationTypeEnum = z.infer<typeof OccupationTypeEnumSchema>

export const TimeEmployedEnumSchema = z.enum([
  'TRIAL',
  'UP_TO_1',
  'UP_TO_2',
  'UP_TO_3',
  'UP_TO_4',
  'UP_TO_5',
  'MORE_THAN_5'
])
export type TimeEmployedEnum = z.infer<typeof TimeEmployedEnumSchema>

export const SupportedPersonsEnumSchema = z.enum(['ZERO', 'ONE', 'TWO', 'THREE', 'FOUR_OR_MORE'])
export type SupportedPersonsEnum = z.infer<typeof SupportedPersonsEnumSchema>

export const LeasingTypeEnumSchema = z.enum(['FINANCIAL', 'OPERATIONAL'])
export type LeasingTypeEnum = z.infer<typeof LeasingTypeEnumSchema>

export const OwnFundsUnitEnumSchema = z.enum(['EUR', 'PERCENTAGE'])
export type OwnFundsUnitEnum = z.infer<typeof OwnFundsUnitEnumSchema>

export const LeasingPeriodEnumSchema = z.enum([
  'ONE_YEAR',
  'TWO_YEARS',
  'THREE_YEARS',
  'FOUR_YEARS',
  'FIVE_YEARS',
  'SIX_YEARS',
  'SEVEN_YEARS'
])
export type LeasingPeriodEnum = z.infer<typeof LeasingPeriodEnumSchema>

export const PaymentDayEnumSchema = z.enum(['FIFTEENTH', 'THIRTIETH'])
export type PaymentDayEnum = z.infer<typeof PaymentDayEnumSchema>

export const FinancialObligationTypeEnumSchema = z.enum([
  'HOME_LOAN',
  'CONSUMER_LOAN',
  'CAR_LOAN',
  'FACTORING',
  'PAYMENT_BY_INSTALLMENTS',
  'STUDENT_LOAN',
  'OVERDRAFT',
  'CREDIT_CARD',
  'GUARANTEE',
  'ALIMONY',
  'ARREARS_OF_TAXES'
])
export type FinancialObligationTypeEnum = z.infer<typeof FinancialObligationTypeEnumSchema>

export const BasicContactInfo = z.object({
  name: UserNameSchema,
  email: EmailAddressSchema,
  phoneNumber: PhoneNumberSchema,
  language: UserLanguageEnumSchema
})

export const CallbackRequestSchema = BasicContactInfo.extend({
  topic: TopicEnumSchema
})
export type CallbackRequest = z.infer<typeof CallbackRequestSchema>

export const ClientPersonalDataSchema = BasicContactInfo.extend({
  additionalInfo: z.string().optional()
})
export type ClientPersonalData = z.infer<typeof ClientPersonalDataSchema>

export const AcceptClientPersonalDataRequestSchema = ClientPersonalDataSchema.extend({
  formType: FormTypeSchema
})
export type AcceptClientPersonalDataRequest = z.infer<typeof AcceptClientPersonalDataRequestSchema>

export const BookingRequestSchema = ClientPersonalDataSchema.extend({
  carprofCarId: VehicleIdSchema
})
export type BookingRequest = z.infer<typeof BookingRequestSchema>

export const SubscriptionRequestSchema = z.object({
  email: EmailAddressSchema
})
export type SubscriptionRequest = z.infer<typeof SubscriptionRequestSchema>

export const VehicleOfferRequestSchema = z.object({
  registrationNumber: VehicleRegistrationNumberSchema
})
export type VehicleOfferRequest = z.infer<typeof VehicleOfferRequestSchema>

export const BuyoutCommissionRequestSchema = VehicleOfferRequestSchema.extend({
  fuelId: FuelTypeTranslationIdSchema.or(z.string()), //TODO: reconsider type later
  engineVolCm3: EngineVolumeSchema.optional(),
  engineKw: EnginePowerSchema.optional(),
  imageIds: FileIdSchema.array(),
  make: VehicleMakeSchema,
  model: VehicleModelSchema,
  mileage: MileageSchema,
  requestedPrice: PriceSchema,
  transmissionId: TransmissionTypeTranslationIdSchema,
  nextInspectionDate: LocalDateSchema.optional(),
  year: VehicleYearSchema,
  registrationNumber: VehicleRegistrationNumberSchema,
  location: VehicleLocationSchema.optional()
})
export type BuyoutCommissionRequest = z.infer<typeof BuyoutCommissionRequestSchema>

export const ExchangeRequestSchema = BuyoutCommissionRequestSchema.extend({
  carprofCarId: VehicleIdSchema
})
export type ExchangeRequest = z.infer<typeof ExchangeRequestSchema>

export const LoanTotalAmountSchema = PriceSchema.min(0).max(1000000)
export type LoanTotalAmount = z.infer<typeof LoanTotalAmountSchema>

export const FinancialObligationSchema = z.object({
  amountOrLimit: z.number(),
  financer: z.string().min(1),
  monthlyPayment: z.number(),
  obligationType: FinancialObligationTypeEnumSchema
})

export type FinancialObligation = z.infer<typeof FinancialObligationSchema>

export const InstallmentRequestSchema = z.object({
  carprofCarId: VehicleIdSchema,
  personalIdCode: PersonalIdCodeSchema,
  monthlyIncome: PriceSchema,
  totalAmount: LoanTotalAmountSchema,
  paymentDay: PaymentDayEnumSchema,
  carLoanPeriod: CarLoanPeriodEnumSchema,
  obligations: FinancialObligationSchema.array()
})

export type InstallmentRequest = z.infer<typeof InstallmentRequestSchema>

export const LeasingPersonalDataSchema = z.object({
  personalIdCode: PersonalIdCodeSchema,
  maritalStatus: CivilStatusTypeEnumSchema,
  supportedPersons: SupportedPersonsEnumSchema,
  education: EducationTypeEnumSchema,
  occupation: OccupationTypeEnumSchema,
  employerCompanyName: z.string().min(3).max(20),
  positionHeld: z.string().min(3).max(20),
  timeEmployed: TimeEmployedEnumSchema
})

export type LeasingPersonalDataValues = z.infer<typeof LeasingPersonalDataSchema>

export const LeasingVehicleInfoSchema = z.object({
  carprofCarId: VehicleIdSchema,
  leasingType: LeasingTypeEnumSchema,
  leasingPeriod: LeasingPeriodEnumSchema,
  ownFundsAmount: z.number(),
  ownFundsUnit: OwnFundsUnitEnumSchema,
  paymentDay: PaymentDayEnumSchema
})

export type LeasingVehicleInfo = z.infer<typeof LeasingVehicleInfoSchema>

export const LeasingIncomeSchema = z.object({
  netIncome: z.number(),
  obligations: FinancialObligationSchema.array()
})

export type LeasingIncomeValues = z.infer<typeof LeasingIncomeSchema>

export const LeasingRequest = LeasingPersonalDataSchema.merge(LeasingVehicleInfoSchema.merge(LeasingIncomeSchema))

export type LeasingRequest = z.infer<typeof LeasingRequest>

export const FormVehicleInitialRequestSchema = z.object({
  formType: FormTypeSchema,
  language: UserLanguageEnumSchema,
  phoneNumber: PhoneNumberSchema,
  carNumber: VehicleRegistrationNumberSchema,
  captchaToken: CaptchaTokenSchema,
  formSource: RequestOriginSchema,
  source: z.string()
})

export type FormVehicleInitialRequest = z.infer<typeof FormVehicleInitialRequestSchema>

export const VehicleRegisterDataSchema = z.object({
  registrationNumber: VehicleRegistrationNumberSchema,
  mark: VehicleMakeSchema,
  model: VehicleModelSchema,
  firstRegYear: VehicleYearSchema.optional(),
  engineVolCm3: EngineVolumeSchema.optional(),
  engineKw: EnginePowerSchema.optional(),
  nextInspectionDate: LocalDateSchema.optional(),
  engine: FuelTypeTranslationValueSchema.optional(),
  transmission: TransmissionTypeTranslationValueSchema.optional()
})
export type VehicleRegisterData = z.infer<typeof VehicleRegisterDataSchema>

export const VehicleMntResponseSchema = z.object({
  mntData: VehicleRegisterDataSchema.optional(),
  formUuid: FormIdSchema
})
export type VehicleMntResponse = z.infer<typeof VehicleMntResponseSchema>

export const FormVehicleRequestSchema = z.object({
  carNumber: VehicleRegistrationNumberSchema,
  mark: VehicleMakeSchema,
  model: VehicleModelSchema,
  mileage: MileageSchema.optional(),
  location: VehicleLocationSchema.optional(),
  requestedPrice: PriceSchema,
  fullName: UserNameSchema.optional(),
  email: EmailAddressSchema.optional(),
  additionalInfo: z.string().max(1000).optional()
})
export type FormVehicleRequest = z.infer<typeof FormVehicleRequestSchema>

export const FormPhotosSchema = z.object({
  imageIds: FileIdSchema.array()
})
export type FormPhotos = z.infer<typeof FormPhotosSchema>
